.inputWrapper {
    position: relative;
    display: inline-block;
    width: calc(100% - 32px);
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color:var(--color-back-primary);
}

.inputItem {
    position: relative;
}

.inputSearch {
    display: flex;
}

.svgIcon {
    position: absolute;
    right: 64px;
    top: 15px;
    z-index: 3;
}

.svgIcon svg {
    width: 16px;
    height: 16px;
}

.RU {
    min-width: 46px;
    height: 46px;
    background-color: var(--color-gray-fill);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border-radius: 16px;
}

.RU svg {
    width: 24px;
    height: 24px;
}

.Arrow {
    min-width: 46px;
    height: 46px;
    background-color: var(--color-gray-fill);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 16px;
}

.Arrow svg {
    width: 24px;
    height: 24px;

}

.input {
    height: 44px;
    padding-left: 44px;
    width: calc(98% - 44px);
    /*font-family: Manrope;*/
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    background: var(--color-gray-fill);
    color: var(--color-text-in-light);
    border: none;
    outline: none;
    border-radius: 16px
}

.input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-secondary);
}

.activeInput {
    padding-left: 18px;
}


.svgWrapper {

    position: absolute;
    bottom: 16px;
    left: 32px;
    z-index: 3;

}

.svgWrapper svg {
    width: 20px;
    height: 20px;
}

.tooltip {
    z-index: 5;
    height: calc(100vh - 90px);
    position: absolute;
    overflow-y: scroll;
    background-color: var(--color-back-primary);
    top: 50px;
    left: -20px;
    width: calc(100% + 36px);
    padding: 15px;
    /*margin-top: 20px;*/
    padding-bottom: 200px;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}


.toltipModal {
    width: calc(100vw - 10px);
    left: -43px;
}

.toltipactive {
    opacity: 1;
    pointer-events: auto;
}

.catigoies {
    display: flex;
        flex-direction: column;
}

.activeCat{
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    margin-top: 20px;
    width: calc(100% + 8px);
}

.itemCat {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90px;
    width: 100%;
    margin: 0 4px 20px;
    background-color: var(--color-back-primary);
    flex-direction: column;
}

.itemCat div {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-in-light);
    text-align: center;
    margin-top: 4px;
}

.menuButtonCategory {
    border: none;
    padding: 0;
    width: 90px;
    height: 90px;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    background: var(--color-gray-fill);
    border-radius: 12px;
    position: relative;
}

.menuButtonCategory img {
    width: 90px;
    height: 90px;
}


.wrapperSearch {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 32px);
    background-color: var(--color-back-primary);
    position: relative;
    justify-content: flex-start;
    padding: 0 16px;
    border-radius: 20px 20px 0 0;
    /*margin-top: -4px;*/
    margin-bottom: 10px;
    padding-bottom: 120px;
}

.itemSearch {
    width: 177px;
    margin: 10px 3.5px;
}


.itemSearch .nameSearch {
    font-size: 14px;
    color: #1F1D2B;

    position: relative;
    z-index: 2;
}

.itemSearch .priceSearch {
    font-size: 15px;
    font-weight: 800;
    color: #F85D42;

    position: relative;
    z-index: 2;
}

.menuButtonSearch {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    background-color: #F3F2F8;
}


.menuButtonSearch img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noneSearch {
    padding-top: 80px;
    text-align: center;
    font-size: 14px;
    color: #1F1D2B;
}

.noneSearch svg {
    margin-bottom: 20px;
    position: relative;
    left: 0;
    top: 0;
}

.titleCti {
    font-size: 12px;
    color: #8E919F;
    margin-bottom: 12px;
}
