.wrapper {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 120px;
    margin-bottom: 20px;
    background-color: var(--color-back-primary);
}

.list {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 12px;
    height: 120px;
    overflow-x: scroll;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    scroll-behavior: smooth;
}

.list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.item {
    max-width: 98px;
    text-align: center;
    padding: 0 7px;
}

.item div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: var(--color-text-in-light);
    text-transform: lowercase;

}

.menuButtonCategory {
    border: none;
    padding: 0px;
    width: 90px;
    overflow: hidden;
    height: 90px;
    box-sizing: border-box;
    cursor: pointer;
    background:var(--color-gray-fill);
    border-radius: 12px;
    position: relative;
}

.menuButtonCategory div {
    width: 90px;
height: 90px;
}

.menuButtonCategory img{
    width: 90px;
    height: 90px;
}